.nav-tabs a.nav-link {
    cursor: pointer;
}

#glPeriodsDetailsTabs.nav-tabs a.nav-link.active {
    border-radius: 0.25rem 0 0 0.25rem;
    border-left: 2px solid #dee2e6; /* set the top border to the desired outline color */
    border-top: 2px solid #dee2e6; /* set the top border to the desired outline color */
    border-bottom: 2px solid #dee2e6; /* set the bottom border to the desired outline color */
    border-right: none; /* remove the right border */
    position: relative;
    z-index: 1;
    margin-right: -1px;
    cursor: pointer;
}

#glPeriodsDetailsTabs.nav-tabs a.nav-link {
    border: 0;
    border-radius: 0;
}

#glPeriodsDetailsTabs.nav-tabs a.nav-item {
    width: 100%; /* set the width to 100% to ensure the tabs fill the entire column */
}

#glPeriodsDetailsTabs.nav-tabs {
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    border-right: 1px solid #dee2e6; /* set the bottom border to the desired outline color */
    border-radius: 0;
}
