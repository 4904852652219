.nav-flow-direction {
    text-align: center;
    width: 100%;
}

    .nav-flow-direction::after {
        content: "\F124";
        justify-content: flex-end;
        font-family: 'bootstrap-icons';
    }

#workflow-tabs .nav-link {
    border-width: 1px;
    border-style: solid;
    width: 100%;
}

#workflow-tabs {
    padding-left: 1em;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    border-left: 1px solid #dee2e6; /* set the bottom border to the desired outline color */
    border-radius: 0;
}

    #workflow-tabs .nav-link:disabled[aria-selected="true"] {
        background-color: darkgrey;
        color: white;
        cursor: not-allowed;
    }

    #workflow-tabs .nav-link.completed {
        background-color: darkgreen;
        color: white;
    }

    #workflow-tabs .nav-link.all-done[aria-selected="true"] {
        background-color: darkgrey;
        color: white;
    }

    #workflow-tabs .nav-link div.spinner-border {
        display: none;
    }

    #workflow-tabs .nav-link.processing div.spinner-border {
        display: inline-block;
    }